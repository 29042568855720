import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import * as firebaseui from 'firebaseui';

// import { initializeApp } from 'firebase/app';
// import { getDatabase, ref, set, onValue, push, get, onChildAdded } from 'firebase/firebase-database';

firebase.initializeApp({
    apiKey: "AIzaSyB4vVEjM1Tienb4VgMZomrAc1GB6LEVKgc",
    authDomain: "dice-4c15f.firebaseapp.com",
    databaseURL: "https://dice-4c15f.firebaseio.com",
    projectId: "dice-4c15f",
    storageBucket: "dice-4c15f.appspot.com",
    messagingSenderId: "713418030883",
    appId: "1:713418030883:web:02fa310e6c2389da2ad696",
    measurementId: "G-F8DPQXYE1Q"
});
firebase.analytics();

var db = firebase.database();

const fbAuth = firebase.auth();
var ui = new firebaseui.auth.AuthUI(fbAuth);
if (location.hostname === "localhost") {
  // Point to the RTDB emulator running on localhost.
  console.log("use emulators")
  db.useEmulator("localhost", 9000);
  fbAuth.useEmulator("http://localhost:9099");
}

export function createRoom(roomName, sheets) {
//    console.log(sheets);
    var newRoom = db.ref('rooms').push();
    const roomId = newRoom.key;
    const room = {
        created: Date.now(),
        owner: currentUser()?.uid,
        tovaldir: false,
        sheets: sheets,
        name: roomName,
    };
    newRoom.set(room);
    room.id = roomId;
    return room;
}
export function updateRoom(room) {
    var newRoom = db.ref(`rooms/${room.id}`);
    newRoom.update({name: room.name, sheets: room.sheets});
}

async function listUserRooms(curUser)  /* : Room array -> () */ {
    if(!curUser)
        return [];
    console.log("list for ", curUser);
    try{
    const res = await db.ref(`rooms`).orderByChild("owner").equalTo(curUser.uid).get();
    console.log("listed for ", curUser);
    let val = res.val();
    let rooms = [];
    for (const roomId in val) {
        const room = val[roomId];
        rooms.push({id: roomId, name: room.name, 
            tovaldir: room.tovaldir ?? false, sheets: room.sheets ? room.sheets : []})
    }
    return rooms;
} catch(e) {
    console.error(e);
    return [];
}
}
window.createRoom = createRoom;

export function sendRoll(roomId, roll, rollResult) {
    db.ref(`rooms/${roomId}/rolls`).push().set({ roll: roll, result: rollResult });
}

export function getRoom(roomId/* : string */, callback /* Room -> unit */) {
    const roomRef = db.ref(`rooms/${roomId}`);
    roomRef.on('value', (snapshot => {
        if (snapshot.exists()) {

            const room = snapshot.val();
            callback(snapshot.val());
           console.log("GOT ROOM", room);
        } else {
            console.log("No data available");
        }
    }));
    roomRef.get().then((snapshot) => {
        if (snapshot.exists()) {
            const room = snapshot.val();
            callback(snapshot.val());
//            console.log("GOT ROOM", room);
        } else {
            console.log("No data available");
        }
    });
}

export function subscribeToRolls(roomId/* : string */, f/* : SkillRoll -> int array -> unit */) {
    const roomRef = db.ref(`rooms/${roomId}/rolls`);
    roomRef.on('child_added', (snapshot) => {
        const data = snapshot.val();
//        console.log(data);
        f(data.roll, data.result);
    });

}

export function onAuthStateChanged(callback /* User option -> () */) {
    firebase.auth().onAuthStateChanged(async user => {
        const curUser = currentUser();
        if(curUser) {
            curUser.rooms = await listUserRooms(curUser)
            // const loginForm = document.querySelector("#firebaseui-auth-container");
            // if(loginForm)
            //     loginForm.remove();
        }
        // console.log("CHANGED", curUser);
        callback(curUser);
    });
}

export function loginFlow() {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    // .then(() =>
    console.trace("START LOGIN FLOW")
    ui.start('#firebaseui-auth-container', {
        callbacks: {
            signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                // User successfully signed in.
                // Return type determines whether we continue the redirect automatically
                // or whether we leave that to developer to handle.
                return false;
            },
            uiShown: function () {
                // The widget is rendered.
            }
        },
        signInFlow: 'popup',
        signInOptions: [
            { provider: firebase.auth.EmailAuthProvider.PROVIDER_ID, requireDisplayName: false },
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
    });
    // );
}

export function logout() {
    firebase.auth().signOut();
}
export function currentUser() {
    var user = firebase.auth().currentUser
    console.log("current user:", user);
    if (user === null)
        return null;
    return { name: user.displayName, uid: user.uid };
}
// currentUser();
// loginFlow();
