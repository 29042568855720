import { Union, Record } from "../.fable/fable-library.3.1.16/Types.js";
import { array_type, tuple_type, union_type, list_type, record_type, int32_type, string_type } from "../.fable/fable-library.3.1.16/Reflection.js";

export class SkillRoll extends Record {
    constructor(name, skill, bonus) {
        super();
        this.name = name;
        this.skill = skill;
        this.bonus = (bonus | 0);
    }
}

export function SkillRoll$reflection() {
    return record_type("Roller.Types.SkillRoll", [], SkillRoll, () => [["name", string_type], ["skill", string_type], ["bonus", int32_type]]);
}

export class Model extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["CanRoll", "RollPending", "Rolled"];
    }
}

export function Model$reflection() {
    return union_type("Roller.Types.Model", [], Model, () => [[], [], [["Item1", SkillRoll$reflection()], ["Item2", list_type(int32_type)]]]);
}

export class RollerMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["RollRequest", "RollResult", "WaitForResult", "Reset"];
    }
}

export function RollerMsg$reflection() {
    return union_type("Roller.Types.RollerMsg", [], RollerMsg, () => [[["Item", SkillRoll$reflection()]], [["Item", tuple_type(SkillRoll$reflection(), list_type(int32_type))]], [], []]);
}

export class Character extends Record {
    constructor(name, skills) {
        super();
        this.name = name;
        this.skills = skills;
    }
}

export function Character$reflection() {
    return record_type("Roller.Types.Character", [], Character, () => [["name", string_type], ["skills", array_type(tuple_type(int32_type, array_type(string_type)))]]);
}

export function charA(name) {
    return new Character(name, [[4, ["Magie"]], [3, ["Érudition", "Charisme"]], [2, ["Athlétisme", "Discrétion", "Empathie"]], [1, ["2a", "2b", "2c", "ASD"]]]);
}

