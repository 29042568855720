import { defaultArg } from "../.fable/fable-library.3.1.16/Option.js";
import { map as map_1, tryItem } from "../.fable/fable-library.3.1.16/Array.js";
import * as react from "react";
import { int32ToString } from "../.fable/fable-library.3.1.16/Util.js";
import { map, sum } from "../.fable/fable-library.3.1.16/List.js";
import { singleton, append, delay, toList } from "../.fable/fable-library.3.1.16/Seq.js";
import { SkillRoll, RollerMsg } from "./Types.fs.js";
import { icon } from "../Global.fs.js";

export function faceValue(i) {
    switch (i) {
        case 1: {
            return 1;
        }
        case 2: {
            return -1;
        }
        case 3: {
            return 0;
        }
        case 4: {
            return 0;
        }
        case 5: {
            return -1;
        }
        case 6: {
            return 1;
        }
        default: {
            return 10000;
        }
    }
}

export function tagClassFromBonus(bonus) {
    switch (bonus) {
        case 1: {
            return "is-light";
        }
        case 2: {
            return "is-dark";
        }
        case 3: {
            return "is-info";
        }
        case 4: {
            return "is-primary";
        }
        case 5: {
            return "is-success";
        }
        default: {
            return "is-white";
        }
    }
}

export function dice(i) {
    const labels = ["+", "-", " ", " ", "-", "+"];
    const diceClass = defaultArg(tryItem(i - 1, ["top", "bottom", "left", "right", "front", "back"]), "");
    const label = (n) => defaultArg(tryItem(n - 1, labels), "??");
    return react.createElement("div", {
        className: "column is-narrow dice",
    }, react.createElement("div", {
        className: "cube turn-" + diceClass,
    }, react.createElement("div", {
        className: "cube__face cube__face--front",
    }, react.createElement("p", {}, label(5))), react.createElement("div", {
        className: "cube__face cube__face--back",
    }, react.createElement("p", {}, label(6))), react.createElement("div", {
        className: "cube__face cube__face--right",
    }, react.createElement("p", {}, label(4))), react.createElement("div", {
        className: "cube__face cube__face--left",
    }, react.createElement("p", {}, label(3))), react.createElement("div", {
        className: "cube__face cube__face--top",
    }, react.createElement("p", {}, label(1))), react.createElement("div", {
        className: "cube__face cube__face--bottom",
    }, react.createElement("p", {}, label(2)))));
}

export const CLUBS = "♣";

export const CLUBS_KING = "🃞";

export function root(model, char, dispatch, tovaldir) {
    const showDice = (vv) => (((vv < 0) ? "" : "+") + int32ToString(vv));
    switch (model.tag) {
        case 1: {
            return react.createElement("progress", {
                className: "progress is-small is-primary",
                max: "100",
            }, "15%");
        }
        case 2: {
            const l = model.fields[1];
            const bonus_3 = model.fields[0].bonus | 0;
            return react.createElement("div", {
                className: "dice-container",
            }, react.createElement("div", {
                className: "card",
            }, react.createElement("header", {
                className: "card-header",
            }, react.createElement("div", {
                className: "card-header-title",
            }, react.createElement("span", {
                className: "tag is-primary is-size-4",
            }, model.fields[0].name), react.createElement("div", {
                className: "block is-size-4 ml-2",
            }, model.fields[0].skill)), react.createElement("p", {
                className: "card-header-icon",
            }, react.createElement("i", {
                className: "fas fa-dice",
            }))), react.createElement("div", {
                className: "card-content",
            }, react.createElement("p", {
                className: "title is-1 has-text-centered",
            }, int32ToString(sum(map((i) => faceValue(i), l), {
                GetZero: () => 0,
                Add: (x, y) => (x + y),
            }) + bonus_3))), react.createElement("footer", {
                className: "card-footer",
            }, ...toList(delay(() => append(map((v) => react.createElement("p", {
                className: "card-footer-item is-size-4-touch is-size-4",
            }, showDice(faceValue(v))), l), delay(() => [react.createElement("p", {
                className: "card-footer-item ",
            }, react.createElement("span", {
                className: "tag is-primary is-size-3 is-size-4-touch",
            }, showDice(bonus_3)))])))))), react.createElement("div", {
                className: "dices columns is-mobile is-multiline is-centered",
            }, ...map((i_1) => dice(i_1), l)), react.createElement("button", {
                className: "button is-large is-primary is-centered m-2",
                onClick: (_arg2) => {
                    dispatch(new RollerMsg(3));
                },
            }, icon("fa-undo")));
        }
        default: {
            return react.createElement("div", {}, ...map((tupledArg) => {
                let bonus;
                return react.createElement("div", {
                    className: "columns",
                }, ...map_1((bonus = (tupledArg[0] | 0), (label) => react.createElement("div", {
                    className: "column dice is-one-fifth",
                }, react.createElement("div", {
                    className: "card",
                    onClick: (_arg1) => {
                        dispatch(new RollerMsg(0, new SkillRoll(char.name, label, bonus)));
                    },
                }, react.createElement("div", {
                    className: "card-content",
                }, react.createElement("p", {}, react.createElement("span", {
                    className: "tag mr-2 " + (tovaldir ? "is-dark" : tagClassFromBonus(bonus)),
                }, tovaldir ? CLUBS : ("+" + int32ToString(bonus))), label))))), tupledArg[1]));
            }, toList(delay(() => append(char.skills, delay(() => singleton([0, ["Default"]])))))));
        }
    }
}

