import * as main from "../sass/main.sass";
import { defaultArg, map as map_3, toArray, some } from "./.fable/fable-library.3.1.16/Option.js";
import * as react from "react";
import { keyValueList } from "./.fable/fable-library.3.1.16/MapUtil.js";
import { Helpers_classList } from "./.fable/Fable.React.5.3.6/Fable.React.Helpers.fs.js";
import { comparePrimitives, min, int32ToString, equals } from "./.fable/fable-library.3.1.16/Util.js";
import { icon, toHash } from "./Global.fs.js";
import { DOMAttr, HTMLAttr } from "./.fable/Fable.React.5.3.6/Fable.React.Props.fs.js";
import { strI18N, Model__get_I18N, Msg } from "./Types.fs.js";
import { take, collect, empty, singleton, append as append_1, delay, map, toList, mapIndexed } from "./.fable/fable-library.3.1.16/Seq.js";
import { Browser_Types_Event__Event_get_Value } from "./.fable/Fable.React.5.3.6/Fable.React.Extensions.fs.js";
import { root as root_1, faceValue, tagClassFromBonus } from "./Roller/View.fs.js";
import { empty as empty_1, singleton as singleton_1, length, map as map_1, sum, ofArray, append } from "./.fable/fable-library.3.1.16/List.js";
import { map as map_2, mapIndexed as mapIndexed_1 } from "./.fable/fable-library.3.1.16/Array.js";
import { currentUser, loginFlow } from "./firebasetest.js";
import { toFail, toConsole, printf, join, interpolate, toText } from "./.fable/fable-library.3.1.16/String.js";
import { getJoinLink } from "./Network.fs.js";
import { charA } from "./Roller/Types.fs.js";
import { ProgramModule_mkProgram, ProgramModule_run } from "./.fable/Fable.Elmish.3.0.6/program.fs.js";
import { Program_withReactBatched } from "./.fable/Fable.Elmish.React.3.0.1/react.fs.js";
import { ProgramModule_toNavigable } from "./.fable/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { parseHash } from "./.fable/Fable.Elmish.Browser.3.0.4/parser.fs.js";
import { update, init, urlUpdate as urlUpdate_1, pageParser } from "./State.fs.js";


console.log(some("Commit:"), {
    d: __BUILT_AT__,
    v: __COMMIT_HASH__,
});

export function menuItem(label, page, currentPage) {
    return react.createElement("li", {}, react.createElement("a", keyValueList([Helpers_classList([["is-active", equals(page, currentPage)]]), new HTMLAttr(94, toHash(page))], 1), label));
}

export function charButton(dispatch, c) {
    return react.createElement("div", {
        className: "block",
    }, react.createElement("button", {
        className: "button is-large",
        onClick: (_arg1) => {
            dispatch(new Msg(7, c));
        },
    }, c.name));
}

export function skillRowEditor(dispatch, idx, character, bonus, skills) {
    return react.createElement("div", {
        className: "columns",
    }, ...mapIndexed((i, s) => react.createElement("div", {
        className: "column is-one-fifth",
    }, react.createElement("div", {
        className: "control has-icons-left",
    }, react.createElement("input", {
        onInput: (e) => {
            dispatch(new Msg(14, idx, bonus, i, Browser_Types_Event__Event_get_Value(e)));
        },
        className: "input",
        type: "text",
        defaultValue: s,
    }), react.createElement("span", {
        className: "icon is-left",
    }, react.createElement("span", {
        className: "tag " + tagClassFromBonus(bonus),
    }, "+" + int32ToString(bonus))))), skills));
}

export function sheetEditor(dispatch, idx, character) {
    return react.createElement("div", {
        className: "column is-full",
    }, react.createElement("div", {
        className: "card",
    }, react.createElement("header", {
        className: "card-header",
    }, react.createElement("p", {
        className: "card-header-title",
    }, character.name), react.createElement("a", {
        className: "card-header-icon",
        onClick: (_arg1) => {
            dispatch(new Msg(12, idx));
        },
    }, icon("fa-user-minus"))), react.createElement("div", {
        className: "card-content",
    }, react.createElement("div", {
        className: "content",
    }, ...append(ofArray([react.createElement("div", {
        className: "field is-horizontal",
    }, react.createElement("div", {
        className: "control has-icons-left",
    }, react.createElement("input", {
        onChange: (e) => {
            dispatch(new Msg(13, idx, Browser_Types_Event__Event_get_Value(e)));
        },
        className: "input",
        type: "text",
        value: character.name,
    }), react.createElement("span", {
        className: "icon is-left",
    }, icon("fa-signature")))), react.createElement("div", {
        className: "field",
    }, react.createElement("label", {
        className: "label",
    }, "Skills"))]), toList(map((tupledArg) => skillRowEditor(dispatch, idx, character, tupledArg[0], tupledArg[1]), character.skills)))))));
}

export function sheetsEditor(dispatch, room) {
    return react.createElement("section", {
        className: "section",
    }, react.createElement("h1", {
        className: "title",
    }, room.name), react.createElement("div", {
        className: "block",
    }, (int32ToString(room.sheets.length) + " character") + ((room.sheets.length > 1) ? "s" : ""), react.createElement("p", {
        className: "is-pulled-right",
    }, react.createElement("button", {
        onClick: (_arg1) => {
            dispatch(new Msg(11));
        },
        className: "button is-outlined mr-2",
    }, icon("fa-user-plus")), react.createElement("button", {
        onClick: (_arg2) => {
            dispatch(new Msg(15, room));
        },
        className: "button is-primary mr-2",
    }, icon("fa-save")), react.createElement("button", {
        onClick: (_arg3) => {
            dispatch(new Msg(16, room));
        },
        className: "button is-primary",
    }, icon("fa-play-circle")))), react.createElement("div", {
        className: "columns is-multiline",
    }, ...mapIndexed_1((idx, character) => sheetEditor(dispatch, idx, character), room.sheets)));
}

export function disconnected(user, state, model, dispatch) {
    let user_1;
    const closeModal = (msg) => {
        void document.querySelector(".modal").classList.toggle("is-active", false);
    };
    return react.createElement("section", {
        className: "section",
    }, react.createElement("div", {
        className: "container",
    }, ...((user == null) ? ofArray([react.createElement("div", {
        className: "container",
    }, react.createElement("div", {
        className: "notification is-primary",
    }, Model__get_I18N(model).NeedsToBeLogged)), react.createElement("div", {
        id: "firebaseui-auth-container",
    }), react.createElement("div", {
        className: "block mt-4",
    }, react.createElement("button", {
        className: "button",
        onClick: (_arg1) => {
            loginFlow();
        },
    }, Model__get_I18N(model).LogIn))]) : (user_1 = user, toList(delay(() => ((state.tag === 0) ? append_1((user_1.rooms.length > 0) ? append_1(singleton(react.createElement("div", {
        className: "modal",
    }, react.createElement("div", {
        className: "modal-background",
    }), react.createElement("div", {
        className: "modal-card",
    }, react.createElement("header", {
        className: "modal-card-head",
    }, react.createElement("p", {
        className: "modal-card-title",
    }, toText(interpolate("%s%P() ?", [Model__get_I18N(model).Delete])))), react.createElement("footer", {
        className: "modal-card-foot",
    }, react.createElement("button", {
        className: "button is-danger",
        onClick: (_arg2) => {
            closeModal(void 0);
        },
    }, Model__get_I18N(model).Delete), react.createElement("button", {
        className: "button",
        onClick: (_arg3) => {
            closeModal(void 0);
        },
    }, Model__get_I18N(model).Cancel))))), delay(() => singleton(react.createElement("div", {
        className: "columns is-multiline",
    }, ...toList(delay(() => map((room_1) => react.createElement("div", {
        className: "column",
    }, react.createElement("div", {
        className: "card",
    }, react.createElement("header", {}, react.createElement("p", {
        className: "card-header-title",
    }, room_1.name)), react.createElement("div", {
        className: "card-content",
    }, react.createElement("div", {
        className: "content",
    }, react.createElement("i", {
        className: "fas fa-dice mr-2",
    }), join(" ", map((s_7) => s_7.name, room_1.sheets))), react.createElement("div", {
        className: "content",
    })), react.createElement("footer", {
        className: "card-footer",
    }, react.createElement("a", {
        className: "card-footer-item",
        href: toText(interpolate("#/join/%P()", [room_1.id])),
    }, strI18N((l) => l.Join, model)), react.createElement("a", {
        className: "card-footer-item",
        onClick: (_arg4) => {
            dispatch(new Msg(10, room_1));
        },
    }, strI18N((l_1) => l_1.Edit, model)), react.createElement("a", {
        className: "card-footer-item",
        onClick: (_arg5) => {
            void document.querySelector(".modal").classList.toggle("is-active", true);
        },
    }, strI18N((l_2) => l_2.Delete, model))))), user_1.rooms))))))) : empty(), delay(() => append_1(singleton(react.createElement("div", {
        className: "field",
    }, react.createElement("label", {
        className: "label",
    }, Model__get_I18N(model).RoomName), react.createElement("div", {
        className: "control",
    }, react.createElement("input", {
        className: "input",
        type: "text",
        placeholder: Model__get_I18N(model).RoomName,
        onChange: (e) => {
            dispatch(new Msg(9, Browser_Types_Event__Event_get_Value(e)));
        },
        value: state.fields[0],
    })))), delay(() => singleton(react.createElement("div", {
        className: "field is-grouped",
    }, react.createElement("p", {
        className: "control",
    }, react.createElement("a", {
        className: "button is-primary",
        onClick: (_arg6) => {
            dispatch(new Msg(1));
        },
    }, strI18N((l_3) => l_3.CreateNewRoom, model))))))))) : singleton(sheetsEditor(dispatch, state.fields[0])))))))));
}

export function connected(state, model, dispatch) {
    const rollList = react.createElement("div", {
        className: "section pb-2 pt-0",
    }, react.createElement("div", {
        className: "box",
    }, react.createElement("div", {
        className: "columns is-mobile",
    }, react.createElement("div", {
        className: "column is-narrow pr-0",
    }, react.createElement("i", {
        className: "fas fa-history",
    })), react.createElement("div", {
        className: "column",
    }, react.createElement("table", {}, react.createElement("tbody", {}, ...toList(delay(() => collect((matchValue) => {
        const roll = matchValue[0];
        return singleton(react.createElement("tr", {}, react.createElement("td", {}, react.createElement("span", {
            className: "tag is-primary",
        }, roll.name)), react.createElement("td", {}, react.createElement("span", {
            className: "block ml-2 mr-2",
        }, roll.skill)), react.createElement("td", {}, react.createElement("span", {
            className: "tag mr-2",
        }, toText(printf("+%i"))(roll.bonus))), react.createElement("td", {}, "="), react.createElement("td", {}, react.createElement("span", {
            className: "tag is-primary ml-2",
        }, int32ToString(sum(map_1((i) => faceValue(i), matchValue[1]), {
            GetZero: () => 0,
            Add: (x, y) => (x + y),
        }) + roll.bonus)))));
    }, take(min((x_1, y_1) => comparePrimitives(x_1, y_1), 3, length(state.rollHistory)), state.rollHistory))))))))));
    const matchValue_1 = state.selectedSheet;
    if (matchValue_1 != null) {
        return ofArray([react.createElement("div", {
            className: toText(interpolate("columns skill-list %P()", [state.room.tovaldir ? "tovaldir has-background-success" : ""])),
        }, react.createElement("div", {
            className: "column",
        }, root_1(state.roll, matchValue_1, (arg) => {
            dispatch(new Msg(4, arg));
        }, state.room.tovaldir)), react.createElement("div", {
            className: "column is-one-third is-hidden-mobile",
        }, rollList)), react.createElement("div", {
            className: "is-hidden-tablet roll-list",
        }, rollList)]);
    }
    else {
        return singleton_1(react.createElement("section", {
            className: "section",
        }, react.createElement("div", {
            className: "container",
        }, react.createElement("h1", {
            className: "title",
        }, strI18N((l) => l.PickACharacter, model)), react.createElement("div", {}, ...map_2((sheet) => react.createElement("div", {
            className: "block",
        }, react.createElement("button", {
            className: "button is-large",
            onClick: (e) => {
                dispatch(new Msg(7, sheet));
            },
        }, sheet.name)), state.room.sheets)))));
    }
}

export const Navbar_MenuId = "navbarMenu";

export function joinButton(joinId, e) {
    let arg10;
    if (navigator.share) {
        e.preventDefault();
        void navigator.share({
            text: "Invite Text",
            title: "Invite",
            url: (arg10 = window.location.origin, toText(printf("%s/#/join/%s"))(arg10)(joinId)),
        });
    }
    else {
        const arg10_1 = window.location.origin;
        toConsole(printf("%s/#/join/%s"))(arg10_1)(joinId);
    }
}

export function navBar(dispatch, model, isBurgerOpen) {
    const languageButton = react.createElement("a", {
        className: "navbar-item",
        onClick: (_arg1) => {
            dispatch(new Msg(17));
        },
    }, ...toList(delay(() => append_1(singleton(react.createElement("i", {
        className: "fas fa-language mr-2",
    })), delay(() => ((model.Language.tag === 1) ? singleton("English") : singleton("Français")))))));
    let patternInput;
    const matchValue_1 = model.Home;
    if (matchValue_1.tag === 2) {
        const c = matchValue_1.fields[0];
        patternInput = [toList(delay(() => {
            let joinId;
            return append_1(singleton(react.createElement("div", {
                className: "navbar-item",
            }, react.createElement("div", {
                className: "buttons",
            }, react.createElement("a", {
                className: "button is-primary",
                href: "#/join/" + getJoinLink(c),
                target: "_blank",
                onClick: (joinId = getJoinLink(c), (e) => {
                    joinButton(joinId, e);
                }),
            }, react.createElement("strong", {}, strI18N((l) => l.InvitePlayer, model)))))), delay(() => append_1(ofArray(toArray(map_3((_arg3) => react.createElement("div", {
                className: "navbar-item",
            }, react.createElement("div", {
                className: "buttons",
            }, react.createElement("a", {
                className: "button is-warning",
                onClick: (_arg2) => {
                    dispatch(new Msg(8));
                },
            }, react.createElement("strong", {}, strI18N((l_1) => l_1.ChangeCharacter, model))))), c.selectedSheet))), delay(() => append_1(singleton(react.createElement("div", {
                className: "navbar-item",
            }, "Commit", react.createElement("span", {
                className: "tag ml-2",
            }, __COMMIT_HASH__))), delay(() => append_1(singleton(react.createElement("div", {
                className: "navbar-item",
            }, "Built", react.createElement("span", {
                className: "tag ml-2",
            }, __BUILT_AT__))), delay(() => singleton(react.createElement("a", {
                className: "navbar-item",
                href: "https://github.com/theor/teledice",
            }, react.createElement("i", {
                className: "fab fa-github mr-2",
            }), "Github"))))))))));
        })), c.selectedSheet, c.room.tovaldir];
    }
    else {
        patternInput = [empty_1(), void 0, false];
    }
    const tovaldir = patternInput[2];
    const sheet = patternInput[1];
    const title = map_3((x) => react.createElement("a", {
        onClick: (_arg4) => {
            dispatch(new Msg(8));
        },
    }, react.createElement("span", {
        className: toText(interpolate("tag %s%P() is-light is-medium", [tovaldir ? "is-success" : "is-primary"])),
    }, ...toList(delay(() => (tovaldir ? singleton(x.name + " + TOVALDIR") : singleton(x.name)))))), sheet);
    return react.createElement("nav", {
        ["aria-label"]: "main navigation",
        className: "navbar is-info",
        role: "navigation",
    }, react.createElement("div", {
        className: "navbar-brand",
    }, ...toList(delay(() => append_1(singleton(((sheet == null) ? ((props_36) => ((children_36) => react.createElement("a", keyValueList(props_36, 1), ...children_36))) : ((props_39) => ((children_39) => react.createElement("div", keyValueList(props_39, 1), ...children_39))))(ofArray([new HTMLAttr(65, "navbar-item"), new HTMLAttr(94, "/")]))(ofArray([icon("fa-dice mr-1"), react.createElement("p", {
        className: "ml-1",
    }, defaultArg(title, "Teledice"))]))), delay(() => append_1(singleton(react.createElement("div", {
        className: "navbar-item",
    }, ...toList(delay(() => {
        const matchValue_2 = currentUser();
        if (matchValue_2 != null) {
            return singleton(react.createElement("p", {
                className: "ml-1",
                onClick: (_arg5) => {
                    dispatch(new Msg(19));
                },
            }, matchValue_2.name));
        }
        else {
            return empty();
        }
    })))), delay(() => singleton(react.createElement("div", keyValueList([Helpers_classList([["navbar-burger", true], ["is-active", isBurgerOpen]]), new DOMAttr(40, (_arg6) => {
        dispatch(new Msg(0, !isBurgerOpen));
    })], 1), react.createElement("span", {}), react.createElement("span", {}), react.createElement("span", {})))))))))), react.createElement("div", keyValueList([new HTMLAttr(99, Navbar_MenuId), Helpers_classList([["navbar-menu", true], ["is-active", isBurgerOpen]])], 1), react.createElement("div", {
        className: "navbar-end",
    }, ...toList(delay(() => append_1(patternInput[0], delay(() => append_1(singleton(languageButton), delay(() => ((model.User != null) ? singleton(react.createElement("a", {
        className: "navbar-item",
        onClick: (_arg7) => {
            dispatch(new Msg(19));
        },
    }, Model__get_I18N(model).LogOut)) : empty()))))))))));
}

export function root(model, dispatch) {
    return react.createElement("div", {}, ...toList(delay(() => append_1(singleton(navBar(dispatch, model, model.IsBurgerOpen)), delay(() => {
        let matchValue;
        return map((x) => x, (matchValue = model.Home, (matchValue.tag === 3) ? singleton_1(root_1(matchValue.fields[0], charA("Test"), (arg) => {
            dispatch(new Msg(6, arg));
        }, true)) : ((matchValue.tag === 0) ? singleton_1(disconnected(model.User, matchValue.fields[0], model, dispatch)) : ((matchValue.tag === 2) ? connected(matchValue.fields[0], model, dispatch) : toFail(printf("No view for model %A"))(model.Home)))));
    })))));
}

ProgramModule_run(Program_withReactBatched("elmish-app", ProgramModule_toNavigable((location) => parseHash(pageParser, location), (result_1, model_2) => urlUpdate_1(result_1, model_2), ProgramModule_mkProgram((result) => init(result), (msg, model) => update(msg, model), (model_1, dispatch) => root(model_1, dispatch)))));

