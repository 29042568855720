import { oneOf, top, str, s as s_2, map } from "./.fable/Fable.Elmish.Browser.3.0.4/parser.fs.js";
import { toHash, Page } from "./Global.fs.js";
import { choose, append, singleton, cons, empty, mapIndexed, toArray, ofArray, collect } from "./.fable/fable-library.3.1.16/List.js";
import { ConnectionType, Msg, ConnectedState, Language as Language_1, Room, Model, ModelE, RoomPage } from "./Types.fs.js";
import { Cmd_batch, Cmd_ofSub, Cmd_map, Cmd_none } from "./.fable/Fable.Elmish.3.0.6/cmd.fs.js";
import { equals } from "./.fable/fable-library.3.1.16/Util.js";
import { onAuthStateChanged as onAuthStateChanged_1, currentUser, createRoom, updateRoom, logout } from "./firebasetest.js";
import { init as init_1, update as update_1 } from "./Roller/State.fs.js";
import { join, getJoinLink, broadcast } from "./Network.fs.js";
import { Character, charA, RollerMsg } from "./Roller/Types.fs.js";
import { startsWith, printf, toFail } from "./.fable/fable-library.3.1.16/String.js";
import { map as map_1 } from "./.fable/fable-library.3.1.16/Array.js";
import { ofArray as ofArray_1, mapIndexed as mapIndexed_1, toArray as toArray_1 } from "./.fable/fable-library.3.1.16/Seq.js";
import { defaultArg, some } from "./.fable/fable-library.3.1.16/Option.js";
import { Navigation_modifyUrl } from "./.fable/Fable.Elmish.Browser.3.0.4/navigation.fs.js";

export const pageParser = (() => {
    let parseBefore, parseBefore_2;
    const parsers = ofArray([map((arg0) => (new Page(2, arg0)), (parseBefore = s_2("join"), (state_1) => collect((state) => str(state), parseBefore(state_1)))), map((arg0_1) => (new Page(1, arg0_1)), (parseBefore_2 = s_2("host"), (state_4) => collect((state_3) => str(state_3), parseBefore_2(state_4)))), map(new Page(0), (state_6) => top(state_6))]);
    return (state_7) => oneOf(parsers, state_7);
})();

export function editRoom(f, model) {
    const matchValue = model.Home;
    if (matchValue.tag === 0) {
        const roomPage = matchValue.fields[0];
        if (roomPage.tag === 1) {
            return [new Model(model.CurrentPage, model.User, new ModelE(0, new RoomPage(1, f(roomPage.fields[0])), matchValue.fields[1]), model.IsBurgerOpen, model.Language), Cmd_none()];
        }
        else {
            throw (new Error("impossible"));
        }
    }
    else {
        throw (new Error("impossible"));
    }
}

export function editSheets(fsheets, model) {
    return editRoom((r) => (new Room(r.id, r.name, toArray(fsheets(ofArray(r.sheets))), r.tovaldir)), model);
}

export function editSheet(idx, f, model) {
    return editSheets((list) => mapIndexed((i, c) => {
        if (i === idx) {
            return f(c);
        }
        else {
            return c;
        }
    }, list), model);
}

export function update(msg, model) {
    let list1;
    switch (msg.tag) {
        case 17: {
            return [new Model(model.CurrentPage, model.User, model.Home, model.IsBurgerOpen, equals(model.Language, new Language_1(0)) ? (new Language_1(1)) : (new Language_1(0))), empty()];
        }
        case 19: {
            logout();
            return [new Model(model.CurrentPage, void 0, model.Home, model.IsBurgerOpen, model.Language), empty()];
        }
        case 18: {
            return [new Model(model.CurrentPage, msg.fields[0], model.Home, model.IsBurgerOpen, model.Language), empty()];
        }
        case 0: {
            return [new Model(model.CurrentPage, model.User, model.Home, msg.fields[0], model.Language), empty()];
        }
        default: {
            const matchValue = model.Home;
            switch (matchValue.tag) {
                case 1: {
                    return [model, empty()];
                }
                case 2: {
                    const conn = matchValue.fields[0];
                    switch (msg.tag) {
                        case 7: {
                            return [new Model(model.CurrentPage, model.User, new ModelE(2, new ConnectedState(conn.id, conn.conn, conn.roll, conn.room, msg.fields[0], conn.rollHistory)), model.IsBurgerOpen, model.Language), empty()];
                        }
                        case 8: {
                            return [new Model(model.CurrentPage, model.User, new ModelE(2, new ConnectedState(conn.id, conn.conn, conn.roll, conn.room, void 0, conn.rollHistory)), model.IsBurgerOpen, model.Language), empty()];
                        }
                        case 4: {
                            const rollReq = msg.fields[0];
                            const patternInput = update_1(rollReq, conn.roll, conn.room.tovaldir);
                            const model_3 = new Model(model.CurrentPage, model.User, new ModelE(2, new ConnectedState(conn.id, conn.conn, patternInput[0], conn.room, conn.selectedSheet, conn.rollHistory)), model.IsBurgerOpen, model.Language);
                            if (rollReq.tag === 1) {
                                broadcast(getJoinLink(conn), rollReq.fields[0][0], rollReq.fields[0][1]);
                            }
                            return [model_3, Cmd_map((arg0) => (new Msg(4, arg0)), patternInput[1])];
                        }
                        case 5: {
                            const skillroll_1 = msg.fields[0];
                            const res = msg.fields[1];
                            const patternInput_1 = update_1(new RollerMsg(1, [skillroll_1, res]), conn.roll, conn.room.tovaldir);
                            return [new Model(model.CurrentPage, model.User, new ModelE(2, new ConnectedState(conn.id, conn.conn, patternInput_1[0], conn.room, conn.selectedSheet, cons([skillroll_1, res], conn.rollHistory))), model.IsBurgerOpen, model.Language), Cmd_map((arg0_1) => (new Msg(4, arg0_1)), patternInput_1[1])];
                        }
                        case 3: {
                            return [new Model(model.CurrentPage, model.User, new ModelE(2, new ConnectedState(conn.id, conn.conn, conn.roll, msg.fields[2], conn.selectedSheet, conn.rollHistory)), model.IsBurgerOpen, model.Language), Cmd_none()];
                        }
                        default: {
                            return toFail(printf("no supported %A %A"))(msg)(model);
                        }
                    }
                }
                case 3: {
                    if (msg.tag === 6) {
                        const patternInput_2 = update_1(msg.fields[0], model.Home, true);
                        return [new Model(model.CurrentPage, model.User, new ModelE(3, patternInput_2[0]), model.IsBurgerOpen, model.Language), Cmd_map((arg0_2) => (new Msg(6, arg0_2)), patternInput_2[1])];
                    }
                    else {
                        return toFail(printf("no supported %A %A"))(msg)(model);
                    }
                }
                default: {
                    const state = matchValue.fields[0];
                    const history = matchValue.fields[1];
                    switch (msg.tag) {
                        case 5: {
                            return [new Model(model.CurrentPage, model.User, new ModelE(0, state, cons([msg.fields[0], msg.fields[1]], history)), model.IsBurgerOpen, model.Language), empty()];
                        }
                        case 9: {
                            return [new Model(model.CurrentPage, model.User, new ModelE(0, new RoomPage(0, msg.fields[0]), empty()), model.IsBurgerOpen, model.Language), empty()];
                        }
                        case 10: {
                            return [new Model(model.CurrentPage, model.User, new ModelE(0, new RoomPage(1, msg.fields[0]), empty()), model.IsBurgerOpen, model.Language), empty()];
                        }
                        case 15: {
                            updateRoom(msg.fields[0]);
                            return [model, Cmd_none()];
                        }
                        case 16: {
                            const room_2 = msg.fields[0];
                            updateRoom(room_2);
                            return [model, Cmd_ofSub((dispatch) => {
                                join(room_2.id, dispatch);
                            })];
                        }
                        case 11: {
                            return editSheets((list1 = singleton(charA("New")), (list2) => append(list1, list2)), model);
                        }
                        case 13: {
                            return editSheet(msg.fields[0], (c) => (new Character(msg.fields[1], c.skills)), model);
                        }
                        case 12: {
                            return editSheets((arg) => choose((x) => x, mapIndexed((i, c_1) => {
                                if (i === msg.fields[0]) {
                                    return void 0;
                                }
                                else {
                                    return c_1;
                                }
                            }, arg)), model);
                        }
                        case 14: {
                            return editSheet(msg.fields[0], (c_2) => (new Character(c_2.name, map_1((tupledArg) => {
                                const b = tupledArg[0] | 0;
                                const skills = tupledArg[1];
                                if (b === msg.fields[1]) {
                                    return [b, toArray_1(mapIndexed_1((si, s_1) => {
                                        if (si === msg.fields[2]) {
                                            return msg.fields[3];
                                        }
                                        else {
                                            return s_1;
                                        }
                                    }, ofArray_1(skills)))];
                                }
                                else {
                                    return [b, skills];
                                }
                            }, c_2.skills))), model);
                        }
                        case 1: {
                            return [new Model(model.CurrentPage, model.User, new ModelE(0, new RoomPage(1, createRoom((state.tag === 0) ? state.fields[0] : (() => {
                                throw (new Error("not possible"));
                            })(), [])), empty()), model.IsBurgerOpen, model.Language), empty()];
                        }
                        case 2: {
                            return [model, Cmd_ofSub((dispatch_1) => {
                                join(msg.fields[0], dispatch_1);
                            })];
                        }
                        case 3: {
                            return [new Model(model.CurrentPage, model.User, new ModelE(2, new ConnectedState(msg.fields[0], new ConnectionType(1, msg.fields[1]), init_1(), msg.fields[2], void 0, history)), model.IsBurgerOpen, model.Language), empty()];
                        }
                        default: {
                            return toFail(printf("no supported %A %A"))(msg)(model);
                        }
                    }
                }
            }
        }
    }
}

export function urlUpdate(result, model) {
    if (result != null) {
        if (result.tag === 2) {
            const hostId = result.fields[0];
            return update(new Msg(2, hostId), model);
        }
        else {
            const page = result;
            return [new Model(page, model.User, model.Home, model.IsBurgerOpen, model.Language), empty()];
        }
    }
    else {
        console.error(some("Error parsing url"));
        return [model, Navigation_modifyUrl(toHash(model.CurrentPage))];
    }
}

export function init(result) {
    const home = init_1();
    let patternInput;
    const defaultLang = startsWith(defaultArg(navigator.language, "en"), "fr", 3) ? (new Language_1(1)) : (new Language_1(0));
    patternInput = urlUpdate(result, new Model(new Page(0), currentUser(), new ModelE(0, new RoomPage(0, ""), empty()), false, defaultLang));
    return [patternInput[0], Cmd_batch(ofArray([patternInput[1], Cmd_ofSub((dispatch_1) => {
        let dispatch;
        onAuthStateChanged_1((dispatch = dispatch_1, (u) => {
            console.log(some("onAuthStateChanged"), u);
            return (u != null) ? dispatch(new Msg(18, u)) : dispatch(new Msg(19));
        }));
    })]))];
}

