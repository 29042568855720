import { getRoom, sendRoll, subscribeToRolls } from "./firebasetest.js";
import { toArray, ofArray } from "./.fable/fable-library.3.1.16/List.js";
import { Msg } from "./Types.fs.js";
import { Auto_generateDecoderCached_1374562F } from "./.fable/Thoth.Json.3.5.0/Decode.fs.js";
import { Character$reflection } from "./Roller/Types.fs.js";
import { list_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import { now } from "./.fable/fable-library.3.1.16/Date.js";
import { toString } from "./.fable/fable-library.3.1.16/Types.js";

export function getJoinLink(c) {
    const matchValue = c.conn;
    if (matchValue.tag === 0) {
        return c.id;
    }
    else {
        return matchValue.fields[0];
    }
}

export function onRollResult(roomId, dispatch) {
    subscribeToRolls(roomId, (roll, resultArray) => {
        dispatch(new Msg(5, roll, ofArray(resultArray)));
    });
}

export function broadcast(roomId, skillroll, rollres) {
    return sendRoll(roomId, skillroll, toArray(rollres));
}

export const sheetsDecode = Auto_generateDecoderCached_1374562F(void 0, void 0, {
    ResolveType: () => list_type(Character$reflection()),
});

export function join(hostId, dispatch) {
    onRollResult(hostId, dispatch);
    return getRoom(hostId, (room) => {
        let copyOfStruct;
        dispatch(new Msg(3, (copyOfStruct = now(), toString(copyOfStruct)), hostId, room));
    });
}

