import { RollerMsg, Model } from "./Types.fs.js";
import { Cmd_OfFunc_result, Cmd_none } from "../.fable/Fable.Elmish.3.0.6/cmd.fs.js";
import { initialize } from "../.fable/fable-library.3.1.16/List.js";
import { randomNext } from "../.fable/fable-library.3.1.16/Util.js";

export function init() {
    return new Model(0);
}

export function update(msg, model, tovaldir) {
    switch (msg.tag) {
        case 3: {
            return [init(), Cmd_none()];
        }
        case 1: {
            return [new Model(2, msg.fields[0][0], msg.fields[0][1]), Cmd_none()];
        }
        case 2: {
            return [new Model(1), Cmd_none()];
        }
        default: {
            const rnd = {};
            return [new Model(1), Cmd_OfFunc_result(new RollerMsg(1, [msg.fields[0], initialize(4, (_arg2) => (tovaldir ? 6 : randomNext(1, 6 + 1)))]))];
        }
    }
}

