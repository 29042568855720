import { Union, Record } from "./.fable/fable-library.3.1.16/Types.js";
import { class_type, tuple_type, list_type, int32_type, option_type, union_type, record_type, bool_type, array_type, string_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import { RollerMsg$reflection, SkillRoll$reflection, Model$reflection as Model$reflection_1, Character$reflection } from "./Roller/Types.fs.js";
import { Page$reflection } from "./Global.fs.js";

export class Room extends Record {
    constructor(id, name, sheets, tovaldir) {
        super();
        this.id = id;
        this.name = name;
        this.sheets = sheets;
        this.tovaldir = tovaldir;
    }
}

export function Room$reflection() {
    return record_type("App.Types.Room", [], Room, () => [["id", string_type], ["name", string_type], ["sheets", array_type(Character$reflection())], ["tovaldir", bool_type]]);
}

export class User extends Record {
    constructor(uid, name, rooms) {
        super();
        this.uid = uid;
        this.name = name;
        this.rooms = rooms;
    }
}

export function User$reflection() {
    return record_type("App.Types.User", [], User, () => [["uid", string_type], ["name", string_type], ["rooms", array_type(Room$reflection())]]);
}

export class ConnectionType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Host", "Client"];
    }
}

export function ConnectionType$reflection() {
    return union_type("App.Types.ConnectionType", [], ConnectionType, () => [[], [["serverId", string_type]]]);
}

export class ConnectedState extends Record {
    constructor(id, conn, roll, room, selectedSheet, rollHistory) {
        super();
        this.id = id;
        this.conn = conn;
        this.roll = roll;
        this.room = room;
        this.selectedSheet = selectedSheet;
        this.rollHistory = rollHistory;
    }
}

export function ConnectedState$reflection() {
    return record_type("App.Types.ConnectedState", [], ConnectedState, () => [["id", string_type], ["conn", ConnectionType$reflection()], ["roll", Model$reflection_1()], ["room", Room$reflection()], ["selectedSheet", option_type(Character$reflection())], ["rollHistory", list_type(tuple_type(SkillRoll$reflection(), list_type(int32_type)))]]);
}

export class RoomPage extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Create", "Edit"];
    }
}

export function RoomPage$reflection() {
    return union_type("App.Types.RoomPage", [], RoomPage, () => [[["Item", string_type]], [["Item", Room$reflection()]]]);
}

export class ModelE extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Disconnected", "Connecting", "Connected", "SimpleRoller"];
    }
}

export function ModelE$reflection() {
    return union_type("App.Types.ModelE", [], ModelE, () => [[["Item1", RoomPage$reflection()], ["Item2", list_type(tuple_type(SkillRoll$reflection(), list_type(int32_type)))]], [], [["Item", ConnectedState$reflection()]], [["Item", Model$reflection_1()]]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ToggleNavbarBurger", "CreateRoom", "DoJoin", "Joining", "Roll", "ReceiveRoll", "SimpleRollerMsg", "SelectCharacter", "UnselectCharacter", "ChangeRoomName", "EditRoom", "AddCharacter", "RemoveCharacter", "RenameCharacter", "RenameSkill", "SaveRoom", "SaveRoomAndJoin", "ToggleLanguage", "Login", "Logout"];
    }
}

export function Msg$reflection() {
    return union_type("App.Types.Msg", [], Msg, () => [[["Item", bool_type]], [], [["serverId", string_type]], [["id", string_type], ["serverId", string_type], ["room", Room$reflection()]], [["Item", RollerMsg$reflection()]], [["Item1", SkillRoll$reflection()], ["Item2", list_type(int32_type)]], [["Item", RollerMsg$reflection()]], [["Item", Character$reflection()]], [], [["newName", string_type]], [["Item", Room$reflection()]], [], [["Item", int32_type]], [["Item1", int32_type], ["Item2", string_type]], [["charIndex", int32_type], ["bonus", int32_type], ["skillIndex", int32_type], ["newSkillName", string_type]], [["Item", Room$reflection()]], [["Item", Room$reflection()]], [], [["Item", User$reflection()]], []]);
}

export class LanguageEnglish {
    constructor() {
    }
    get DisplayName() {
        return "English";
    }
    get PickACharacter() {
        return "Pick a character";
    }
    get InvitePlayer() {
        return "Invite Players";
    }
    get ChangeCharacter() {
        return "Pick another character";
    }
    get NeedsToBeLogged() {
        return "Only authenticated users can create a game";
    }
    get LogIn() {
        return "Log In";
    }
    get LogOut() {
        return "Log Out";
    }
    get Join() {
        return "Join";
    }
    get Edit() {
        return "Edit";
    }
    get Delete() {
        return "Delete";
    }
    get Cancel() {
        return "Cancel";
    }
    get RoomName() {
        return "RoomName";
    }
    get CreateNewRoom() {
        return "Create new room";
    }
}

export function LanguageEnglish$reflection() {
    return class_type("App.Types.LanguageEnglish", void 0, LanguageEnglish);
}

export function LanguageEnglish_$ctor() {
    return new LanguageEnglish();
}

export class LanguageFrench {
    constructor() {
    }
    get DisplayName() {
        return "Français";
    }
    get PickACharacter() {
        return "Choix du personnage";
    }
    get InvitePlayer() {
        return "Inviter un joueur";
    }
    get ChangeCharacter() {
        return "Changer de joueur";
    }
    get NeedsToBeLogged() {
        return "Vous devez être connecté pour créer une partie";
    }
    get LogIn() {
        return "Connexion";
    }
    get LogOut() {
        return "Déconnexion";
    }
    get Join() {
        return "Rejoindre";
    }
    get Edit() {
        return "Modifier";
    }
    get Delete() {
        return "Supprimer";
    }
    get Cancel() {
        return "Annuler";
    }
    get RoomName() {
        return "Nom de la partie";
    }
    get CreateNewRoom() {
        return "Créer une nouvelle partie";
    }
}

export function LanguageFrench$reflection() {
    return class_type("App.Types.LanguageFrench", void 0, LanguageFrench);
}

export function LanguageFrench_$ctor() {
    return new LanguageFrench();
}

export class Language extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["English", "French"];
    }
}

export function Language$reflection() {
    return union_type("App.Types.Language", [], Language, () => [[], []]);
}

export class Model extends Record {
    constructor(CurrentPage, User, Home, IsBurgerOpen, Language) {
        super();
        this.CurrentPage = CurrentPage;
        this.User = User;
        this.Home = Home;
        this.IsBurgerOpen = IsBurgerOpen;
        this.Language = Language;
    }
}

export function Model$reflection() {
    return record_type("App.Types.Model", [], Model, () => [["CurrentPage", Page$reflection()], ["User", option_type(User$reflection())], ["Home", ModelE$reflection()], ["IsBurgerOpen", bool_type], ["Language", Language$reflection()]]);
}

export function Model__get_I18N(this$) {
    if (this$.Language.tag === 1) {
        return LanguageFrench_$ctor();
    }
    else {
        return LanguageEnglish_$ctor();
    }
}

export function i18n(f, m) {
    return f(Model__get_I18N(m));
}

export function strI18N(f, m) {
    return i18n(f, m);
}

