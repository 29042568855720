import { Union } from "./.fable/fable-library.3.1.16/Types.js";
import { union_type, string_type } from "./.fable/fable-library.3.1.16/Reflection.js";
import * as react from "react";

export class Page extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Home", "Host", "Join", "NotFound"];
    }
}

export function Page$reflection() {
    return union_type("Global.Page", [], Page, () => [[], [["hostId", string_type]], [["hostId", string_type]], []]);
}

export function toHash(page) {
    switch (page.tag) {
        case 1: {
            return "#host/" + page.fields[0];
        }
        case 2: {
            return "#join/" + page.fields[0];
        }
        case 3: {
            return "#notFound";
        }
        default: {
            return "#home";
        }
    }
}

export function icon(s) {
    return react.createElement("i", {
        className: "fas " + s,
    });
}

